var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "orderTest-container" },
    [
      _c(
        "el-form",
        { ref: "form", attrs: { model: _vm.form, inline: "" } },
        [
          _c(
            "el-form-item",
            { attrs: { label: "", prop: "" } },
            [
              _c("el-date-picker", {
                staticStyle: { width: "250px" },
                attrs: {
                  type: "daterange",
                  "range-separator": "-",
                  "value-format": "yyyy-MM-dd",
                  "start-placeholder": "开始日期",
                  "end-placeholder": "结束日期",
                },
                model: {
                  value: _vm.time,
                  callback: function ($$v) {
                    _vm.time = $$v
                  },
                  expression: "time",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "", prop: "deposit_id" } },
            [
              _c(
                "el-select",
                {
                  staticStyle: { width: "140px" },
                  attrs: { clearable: "", placeholder: "请选择活动" },
                  model: {
                    value: _vm.form.deposit_id,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "deposit_id", $$v)
                    },
                    expression: "form.deposit_id",
                  },
                },
                _vm._l(_vm.activeList, function (i, idx) {
                  return _c("el-option", {
                    key: idx,
                    attrs: { value: i.id, label: i.name },
                  })
                }),
                1
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "", prop: "staff_id" } },
            [
              _c(
                "el-select",
                {
                  staticStyle: { width: "140px" },
                  attrs: { clearable: "", placeholder: "请选择业务员" },
                  model: {
                    value: _vm.form.staff_id,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "staff_id", $$v)
                    },
                    expression: "form.staff_id",
                  },
                },
                _vm._l(_vm.staffList, function (i, idx) {
                  return _c("el-option", {
                    key: idx,
                    attrs: { value: i.id, label: i.name },
                  })
                }),
                1
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "", prop: "" } },
            [
              _c("goods-search", {
                ref: "goodsSearch",
                staticStyle: { width: "140px" },
                attrs: { "is-table": false },
                on: { "select-goods-all": _vm.selectGoods },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "", prop: "" } },
            [
              _c("client-search", {
                ref: "clientSearch",
                staticStyle: { width: "140px" },
                attrs: { "popper-class": " selectDC" },
                on: { "select-id": _vm.selectCust },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "", prop: "bill_code" } },
            [
              _c("el-input", {
                staticStyle: { width: "140px" },
                attrs: { placeholder: "预存款单号" },
                model: {
                  value: _vm.form.bill_code,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "bill_code", $$v)
                  },
                  expression: "form.bill_code",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "", prop: "info" } },
            [
              _c("el-input", {
                staticStyle: { width: "140px" },
                attrs: { placeholder: "备注" },
                model: {
                  value: _vm.form.info,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "info", $$v)
                  },
                  expression: "form.info",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "" } },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: {
                    click: function () {
                      _vm.form.pageSize = 10
                      _vm.form.pageNo = 1
                      _vm.fetchData()
                    },
                  },
                },
                [_vm._v(" 查询 ")]
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "" } },
            [
              _c("el-button", { on: { click: _vm.clearForm } }, [
                _vm._v("清空"),
              ]),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "" } },
            [
              _c("el-button", { on: { click: _vm.hanlderExport } }, [
                _vm._v("导出"),
              ]),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loading,
              expression: "loading",
            },
          ],
          attrs: { stripe: "", data: _vm.list },
        },
        [
          _c(
            "el-table-column",
            {
              attrs: { align: "center", width: "50" },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function (ref) {
                    var row = ref.row
                    var $index = ref.$index
                    return [
                      row.bill_code == "合计"
                        ? _c("div")
                        : _c("div", [_vm._v(_vm._s($index + 1))]),
                    ]
                  },
                },
              ]),
            },
            [
              _c(
                "template",
                { slot: "header" },
                [
                  _c(
                    "el-popover",
                    {
                      attrs: {
                        "popper-class": "custom-table-checkbox",
                        trigger: "hover",
                      },
                    },
                    [
                      _c(
                        "el-checkbox-group",
                        {
                          model: {
                            value: _vm.checkList,
                            callback: function ($$v) {
                              _vm.checkList = $$v
                            },
                            expression: "checkList",
                          },
                        },
                        _vm._l(_vm.columns, function (item, index) {
                          return _c("el-checkbox", {
                            key: index,
                            attrs: { label: item.label },
                          })
                        }),
                        1
                      ),
                      _c(
                        "el-button",
                        {
                          attrs: { slot: "reference", type: "text" },
                          slot: "reference",
                        },
                        [
                          _c("vab-remix-icon", {
                            attrs: { icon: "settings-line" },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            2
          ),
          _vm._l(_vm.finallyColumns, function (item, tableIndex) {
            return _c("el-table-column", {
              key: tableIndex,
              attrs: {
                prop: item.prop,
                label: item.label,
                width: "",
                align: "center",
              },
              scopedSlots: _vm._u(
                [
                  {
                    key: "default",
                    fn: function (ref) {
                      var row = ref.row
                      return [
                        item.label == "单号" && row.bill_code !== "合计"
                          ? _c(
                              "div",
                              {
                                staticClass: "underline",
                                on: {
                                  click: function ($event) {
                                    return _vm.handleOrderDetail(row)
                                  },
                                },
                              },
                              [_vm._v(" " + _vm._s(row[item.prop]) + " ")]
                            )
                          : _c("div", [_vm._v(_vm._s(row[item.prop]))]),
                      ]
                    },
                  },
                ],
                null,
                true
              ),
            })
          }),
        ],
        2
      ),
      _c("el-pagination", {
        attrs: {
          "current-page": _vm.form.pageNo,
          layout: _vm.layout,
          "page-size": _vm.form.pageSize,
          total: _vm.total,
          background: "",
        },
        on: {
          "current-change": _vm.handleCurrentChange,
          "size-change": _vm.handleSizeChange,
        },
      }),
      _c("saleOrderDetail", { ref: "saleOrderDetail" }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }